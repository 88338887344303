<template>
  <div class="main-index-container">
    <div id="geologyMainMap" class="map-tianditu"></div>

    <div class="map-left-view" style="width: 280px">
      <div>
        <transition name="map-dataleft">
          <div style="margin-bottom: 20px; padding-left: 20px" v-if="mapDataLeftShow">
            <data-panel title="监测点列表">
              <el-carousel trigger="click" :height="leftHeight" :autoplay="false" class="geology-carousel">
                <el-carousel-item v-for="(
                                        elem, i
                                    ) in monitorDeviceListTabdata" :key="i">
                  <div class="main-danger-info-list">
                    <div v-for="(item, index) in elem" :key="'elem' + index" class="main-danger-info-dangeritem" :class="{
                      active: dangerActiveIndex == i * monitorDeviceListOffset + index,
                    }" @click="changeDanger(i * monitorDeviceListOffset + index, item)">
                      <div class="title">
                        {{ item.hiddanger.DangerName }}
                      </div>
                      <div class="info" style="margin-bottom: 5px">
                        类型：{{
                          item.hiddanger.DangerType
                        }}
                      </div>
                      <div class="info" style="margin-bottom: 5px">
                        等级：{{
                          item.hiddanger.DangerLevel
                        }}
                      </div>
                      <div class="info danger-marks">
                        备注：{{ item.hiddanger.Marks }}
                      </div>
                    </div>
                  </div>
                </el-carousel-item>
              </el-carousel>
            </data-panel>
          </div>
        </transition>
      </div>

      <div class="panel-foldopen-left" @click="mapDataLeftShow = !mapDataLeftShow">
        <template v-if="!mapDataLeftShow">
          <i class="icon el-icon-caret-right"></i>
          <i class="icon el-icon-caret-right"></i>
        </template>
        <template v-else>
          <i class="icon el-icon-caret-left"></i>
          <i class="icon el-icon-caret-left"></i>
        </template>
      </div>
    </div>

    <div class="map-right-view" style="width: 280px">
      <div style="height: 660px" v-if="monitorDeviceListOffset == 5">
        <transition name="map-dataright">
          <div style="margin-bottom: 20px; padding-right: 20px" v-if="mapDataRightShow">
            <data-panel title="实时预警" body-class="warning-early-panel">
              <el-row>
                <!-- <el-col :span="6" style="color: #32c5ff">
                                    <div class="label">注意级</div>
                                    <div class="value">
                                        {{ waringDataCount.warningZyData }}
                                    </div>
                                </el-col> -->
                <el-col :span="8" style="color: #fbca29">
                  <div class="label">警示级</div>
                  <div class="value">
                    {{ waringDataCount.warningJsData }}
                  </div>
                </el-col>
                <el-col :span="8" style="color: #ff9e17">
                  <div class="label">警戒级</div>
                  <div class="value">
                    {{ waringDataCount.warningJjData }}
                  </div>
                </el-col>
                <el-col :span="8" style="color: #ff3b20">
                  <div class="label">警报级</div>
                  <div class="value">
                    {{ waringDataCount.warningJbData }}
                  </div>
                </el-col>
              </el-row>
            </data-panel>
            <data-panel title="历史预警信息">
              <panel-warning-info :scroll-time="50" :min-scroll-line="5" :continuity="true" :data="waringDataList">
              </panel-warning-info>
            </data-panel>
            <data-panel title="设备在线统计">
              <panel-device-state :total="deviceTotalCount" :online="deviceOnlineCount" :refresh="false"
                :refresh-time="10000"></panel-device-state>
            </data-panel>
            <data-panel title="设备占比">
              <panel-device-radio id="deviceDataRadio" style="height: 180px" :data="deviceRadioData">
              </panel-device-radio>
            </data-panel>
          </div>
        </transition>
      </div>
      <div style="height: 480px" v-if="monitorDeviceListOffset == 4">
        <transition name="map-dataright">
          <div style="margin-bottom: 20px; padding-right: 20px" v-if="mapDataRightShow">
            <el-carousel trigger="click" height="480px" :autoplay="false" class="geology-carousel">
              <el-carousel-item>
                <data-panel title="实时预警" body-class="warning-early-panel">
                  <el-row>
                    <!-- <el-col
                                            :span="6"
                                            style="color: #32c5ff"
                                        >
                                            <div class="label">注意级</div>
                                            <div class="value">
                                                {{
                                                    waringDataCount.warningZyData
                                                }}
                                            </div>
                                        </el-col> -->
                    <el-col :span="8" style="color: #fbca29">
                      <div class="label">警示级</div>
                      <div class="value">
                        {{
                          waringDataCount.warningJsData
                        }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="color: #ff9e17">
                      <div class="label">警戒级</div>
                      <div class="value">
                        {{
                          waringDataCount.warningJjData
                        }}
                      </div>
                    </el-col>
                    <el-col :span="8" style="color: #ff3b20">
                      <div class="label">警报级</div>
                      <div class="value">
                        {{
                          waringDataCount.warningJbData
                        }}
                      </div>
                    </el-col>
                  </el-row>
                </data-panel>
                <data-panel title="历史预警信息">
                  <panel-warning-info :scroll-time="50" :min-scroll-line="5" :continuity="true" :data="waringDataList">
                  </panel-warning-info>
                </data-panel>
                <data-panel title="设备在线统计">
                  <panel-device-state :total="deviceTotalCount" :online="deviceOnlineCount" :refresh="false"
                    :refresh-time="10000"></panel-device-state>
                </data-panel>
              </el-carousel-item>
              <el-carousel-item>
                <data-panel title="设备占比">
                  <panel-device-radio id="deviceDataRadio" style="height: 180px" :data="deviceRadioData">
                  </panel-device-radio>
                </data-panel>
              </el-carousel-item>
            </el-carousel>
          </div>
        </transition>
      </div>

      <div style="text-align: right">
        <div class="panel-foldopen-right" @click="mapDataRightShow = !mapDataRightShow">
          <template v-if="mapDataRightShow">
            <i class="icon el-icon-caret-right"></i>
            <i class="icon el-icon-caret-right"></i>
          </template>
          <template v-else>
            <i class="icon el-icon-caret-left"></i>
            <i class="icon el-icon-caret-left"></i>
          </template>
        </div>
      </div>
    </div>

    <el-dialog title="监测点详情" :visible.sync="dialogDangerDetailVisible" width="60%" custom-class="geology-dialog"
      :modal-append-to-body="true">
      <danger-detail :detailid="dialogDangerId" :visible="dialogDangerDetailVisible"></danger-detail>
      <div slot="footer" class="dialog-footer">
        <el-button size="small" style="margin-left: 10px" plain type="primary"
          @click="dialogDangerDetailVisible = false">关
          闭</el-button>
      </div>
    </el-dialog>

    <el-dialog title="监测点位详情" :visible.sync="dialogMonitorDetailVisible" width="60%" custom-class="geology-dialog"
      :modal-append-to-body="true">
      <monitor-detail :detailid="dialogMonitorId" :visible="dialogMonitorDetailVisible"></monitor-detail>

      <div slot="footer" class="dialog-footer">
        <el-button size="small" style="margin-left: 10px" plain type="primary"
          @click="dialogMonitorDetailVisible = false">关
          闭</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style lang="scss">
.main-index-container {
  position: relative;
  height: 100%;
}

#geologyMainMap {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 100%;

  .tdt-label {
    background: transparent;
    color: #4ee7f3;
    border: none;
    box-shadow: none;
    font-size: 14px;
    font-weight: 700;
  }
}

.map-bottom-view-scrollbar {
  flex: 1;

  .map-bottom-view-scrollbar-wrapper {
    overflow-x: hidden;
  }
}
</style>
<script>
import Vue from "vue";
import Tianditu from "@/common/tianditu";
import dataPanel from "./components/data-panel";
import panelWarningInfo from "./components/panel-warning-info";
import panelDeviceState from "./components/panel-device-state";
import panelDeviceRadio from "./components/panel-device-radio";

import dangerDetail from "../danger/components/dangerDetail";
import monitorDetail from "../monitor/components/monitorDetail";
import {
  getMonitorDeviceList,
  getWaringMsgAndCount,
  getHomeDeviceCount,
  getHomeDeviceRadio,
} from "@/api/user";
import { getMonitorList } from '@/api/base';
import IconMapDw1 from "@/assets/map-dw1.png"
import IconMapDw2 from "@/assets/map-dw2.png"

Vue.use(Tianditu);
export default {
  data() {
    return {
      tMap: "",
      ///
      // {
      //     data:'',
      //     marker:'',
      //     monitor: [{
      //         data:'',
      //         marker:'',
      //         label:'',
      //         devices: [{
      //             data:'',
      //             marker:'',
      //             label:'',
      //         }],
      //     }]
      // }
      ///
      dangerMarkers: [],

      mapDataLeftShow: true,
      mapDataRightShow: true,
      mapDataBottomShow: true,

      waringDataCount: "",
      waringDataList: [],

      deviceTotalCount: 0,
      deviceOnlineCount: 0,

      deviceRadioData: "",

      monitorDeviceListOffset: 4, //左侧监测点列表个数
      monitorDeviceListTabdata: [],
      monitorDeviceList: [],
      activeIndex: 0,

      dangerActiveIndex: -1,

      //弹出灾害点详情
      dialogDangerDetailVisible: false,
      dialogDangerId: 0,

      //是否显示监测点位
      canShowMonitor: false,

      //是否显示设备点位
      canShowDevice: false,

      //弹出监测点位详情
      dialogMonitorDetailVisible: false,
      dialogMonitorId: 0,

      //2024.09.05地图显示点修改
      prevMapZoom: 0,
      mapShowData: {
        danger: [],
        monitor: [],
        device: [],
      },
      mapShowMarkers: {
        danger: [],//{marker:'',label:''}
        monitor: [],
        device: [],
      },
    };
  },
  components: {
    dataPanel,
    panelWarningInfo,
    panelDeviceState,
    panelDeviceRadio,
    dangerDetail,
    monitorDetail,
  },
  computed: {
    leftHeight() {
      if (this.monitorDeviceList.length < this.monitorDeviceListOffset) {
        return this.monitorDeviceList.length * 120 + 40 + "px";
      }
      if (this.monitorDeviceListOffset == 4) {
        return "480px";
      } else {
        return "600px";
      }
    },
  },
  mounted() {
    var that = this;
    this.$tianditu.init({
      key: "328016e1243b27658e2b50b7cadda87b",
      el: "geologyMainMap",
      config: {
        minZoom: 4,
        maxZoom: 18,
        datasourcesControl: false,
      },
      success(map) {
        that.tMap = map;
        map.setMapType(window.TMAP_HYBRID_MAP);
        // map.centerAndZoom(
        //   new T.LngLat(101.63519449375, 27.5221525625),
        //   8
        // );
        map.addEventListener('zoomend', function (options) {
          // 一、标段显示：1 - 12级别
          // 二、点位显示：13 - 15级别
          // 三、设备显示：16 - 不限制
          that.showMapPoint();


          // if (options.target.getZoom() >= 10) {
          //   //添加监测点位覆盖物
          //   that.canShowMonitor = true;
          //   if (options.target.getZoom() >= 16) {
          //     that.canShowDevice = true;
          //   } else {
          //     if (that.canShowDevice) {
          //       //清除已显示的设备
          //       that.clearMapDevice();
          //     }
          //     that.canShowDevice = false;
          //   }
          // } else {
          //   //清除监测点位覆盖物
          //   if (that.canShowMonitor) {
          //     //从16以上变小到16以下，则需要执行显示灾害点位
          //     that.clearMapMonitor();
          //   }
          //   that.canShowMonitor = false;
          // }
        })
        map.addEventListener('moveend', function (options) {
          if (that.canShowMonitor) {
            //that.showMapPoint();
            //that.showMapMonitor();
          }
        })
        getMonitorDeviceList().then((res) => {
          if (res.code == 0) {
            var offset = 4;
            if (document.body.offsetHeight > 700) {
              offset = 5;
            }
            var resdata = [];
            for (
              let index = 0;
              index < res.data.length;
              index = index + offset
            ) {
              var listdata = [];
              for (let i = 0; i < offset; i++) {
                if (index + i < res.data.length) {
                  listdata.push(res.data[index + i]);
                }
              }
              resdata.push(listdata);
            }
            that.monitorDeviceListOffset = offset;
            that.monitorDeviceListTabdata = resdata;
            that.monitorDeviceList = res.data;
            //that.changeDanger(-1);

            // 2024.09.05
            //获取所有灾害点的中心位置
            let dangerList = res.data.map(item => item.hiddanger).filter(item => item.Longitude && item.Longitude > 0 && item.Latitude && item.Latitude > 0);
            let longitudes = dangerList.map(item => item.Longitude * 1);
            let latitudes = dangerList.map(item => item.Latitude * 1);

            let centerLongitudes = (Math.min(...longitudes) + Math.max(...longitudes)) / 2;
            let centerLatitudes = (Math.min(...latitudes) + Math.max(...latitudes)) / 2;
            map.centerAndZoom(
              new T.LngLat(centerLongitudes, centerLatitudes),
              10
            );


            that.mapShowData.danger = res.data;
            let monitorList = [];
            res.data.forEach(item => {
              monitorList.push(...item.monitorlist);
            })
            that.mapShowData.monitor = monitorList;
            let deviceList = [];
            monitorList.forEach(item => {
              deviceList.push(...item.deviceList);
            })
            that.mapShowData.device = deviceList;

            that.showMapPoint();
          }
        });
      },
    });
  },
  methods: {
    getMapInfoHtml(monitorinfo) {
      var html =
        '<div class="map-dialog-container"><div class="map-dialog-title">监测点位</div><div class="map-dialog-localname">' +
        monitorinfo.MonitorName +
        '</div><div class="map-dialog-localitem"><label>所属隐患点</label><div class="map-dialog-localvalue">' +
        monitorinfo.DangerName +
        '</div></div><div class="map-dialog-localitem"><label for="">联系人</label><div class="map-dialog-localvalue">' +
        monitorinfo.Uname +
        '</div></div><div class="map-dialog-localitem"><label for="">联系电话</label><div class="map-dialog-localvalue">' +
        monitorinfo.Mobile +
        '</div></div><div class="map-dialog-localitem"><label for="">等级</label><div class="map-dialog-localvalue">' +
        monitorinfo.DangerLevel +
        '</div></div><div  class="map-dialog-actions"><a href="/device/detail?id=' +
        monitorinfo.DeviceId +
        '" class="map-dialog-btn">数据查看 >></a></div></div>';
      return html;
    },
    //========================
    // 2024.09.05修改根据地图缩放级别显示对应的点
    // 一、标段显示：1 - 12级别
    // 二、点位显示：13 - 15级别
    // 三、设备显示：16 - 不限制
    showMapPoint() {
      let map = this.tMap;
      let mapCurrentZoom = map.getZoom();
      if (mapCurrentZoom <= 12) {
        if (this.prevMapZoom > 12 || this.mapShowMarkers.danger.length == 0) {
          this.clearMapMarkerMonitor();
          this.clearMapMarkerDevice();
          this.showMapMarkerDanger();
          //只显示灾害点，清除监测点
        }
      } else if (mapCurrentZoom > 12 && mapCurrentZoom <= 15) {
        if (this.prevMapZoom <= 12 || this.prevMapZoom > 15 || this.mapShowMarkers.monitor.length == 0) {
          this.clearMapMarkerDanger();
          this.clearMapMarkerDevice();
          this.showMapMarkerMonitor();
          //只显示监测点，清除灾害点或设备
        }

      } else {
        if (this.prevMapZoom <= 15 || this.mapShowMarkers.device.length == 0) {
          this.clearMapMarkerDanger();
          this.clearMapMarkerMonitor();
          this.showMapMarkerDevice();
          //只显示设备，清除灾害点、监测点
        }

      }
      this.prevMapZoom = mapCurrentZoom;
    },
    //在地图上显示灾害点位
    showMapMarkerDanger() {
      const that = this;
      let map = this.tMap;
      var dangerMarkers = [];
      let dangerData = this.mapShowData.danger.map(item => item.hiddanger);
      for (let index = 0; index < dangerData.length; index++) {
        const element = dangerData[index];
        var icon1 = new T.Icon({
          iconUrl: IconMapDw1,
          iconSize: new T.Point(32, 32),
          iconAnchor: new T.Point(16, 16),
        });

        (function (nowlocal) {
          var marker = new T.Marker(
            new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
            { icon: icon1, title: nowlocal.DangerName, }
          );
          marker.addEventListener(
            "click",
            function () {
              that.dialogDangerId = nowlocal.Id;
              that.dialogDangerDetailVisible = true;
            },
            this
          );
          map.addOverLay(marker);

          let label = new T.Label({
            position: new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
            text: `<div style="transform: translateX(-70%);">${nowlocal.DangerName}</div>`,
            offset: new T.Point(0, 28),
          });
          map.addOverLay(label);


          dangerMarkers.push({
            data: nowlocal,
            marker: marker,
            label: label,
          });
        })(element);
      }
      this.mapShowMarkers.danger = dangerMarkers;
    },
    //清除地图上的灾害点位
    clearMapMarkerDanger() {
      if (this.mapShowMarkers.danger.length > 0) {
        this.mapShowMarkers.danger.forEach(item => {
          this.tMap.removeOverLay(item.marker);
          this.tMap.removeOverLay(item.label);
        })
        this.mapShowMarkers.danger = [];
      }
    },
    //在地图上显示监测点
    showMapMarkerMonitor() {
      const that = this;
      let map = this.tMap;
      let monitorMarkers = [];
      let monitorListData = this.mapShowData.monitor;
      monitorListData.forEach(item => {
        var icon2 = new T.Icon({
          iconUrl: IconMapDw2,
          iconSize: new T.Point(32, 32),
          iconAnchor: new T.Point(16, 16),
        });
        if (item.Longitude && item.Latitude) {
          (function (nowlocal) {
            let nowLngLat = new T.LngLat(nowlocal.Longitude, nowlocal.Latitude);
            var marker = new T.Marker(nowLngLat,
              { icon: icon2, title: nowlocal.MonitorName, }
            );
            marker.addEventListener("click", function () {
              that.dialogMonitorId = nowlocal.Id;
              that.dialogMonitorDetailVisible = true;
            }, this);

            let label = new T.Label({
              position: nowLngLat,
              text: `<div style="transform: translateX(-70%);">${nowlocal.MonitorName}</div>`,
              offset: new T.Point(0, 28),
            });
            let itemMark = {
              data: nowlocal,
              marker: marker,
              label: label,
            };
            monitorMarkers.push(itemMark);
            map.addOverLay(marker);
            map.addOverLay(label);
          })(item);
        }
      })
      this.mapShowMarkers.monitor = monitorMarkers;

    },
    //清除地图上的监测点位
    clearMapMarkerMonitor() {
      if (this.mapShowMarkers.monitor.length > 0) {
        this.mapShowMarkers.monitor.forEach(item => {
          this.tMap.removeOverLay(item.marker);
          this.tMap.removeOverLay(item.label);
        })
        this.mapShowMarkers.monitor = [];
      }
    },
    //在地图上显示设备
    showMapMarkerDevice() {
      const that = this;
      const map = this.tMap;
      let deviceList = this.mapShowData.device;
      let deviceMarkers = [];
      deviceList.forEach(item => {
        var icon2 = new T.Icon({
          iconUrl: item.DImage,
          iconSize: new T.Point(32, 32),
          iconAnchor: new T.Point(16, 16),
        });
        if (item.Longitude && item.Latitude) {
          (function (nowlocal) {
            let nowLngLat = new T.LngLat(nowlocal.Longitude, nowlocal.Latitude);
            var marker = new T.Marker(nowLngLat,
              { icon: icon2, title: nowlocal.DeviceName, }
            );
            marker.addEventListener("click", function () {
              //跳转到设备详情 
              that.$router.push('/device/detail?id=' + nowlocal.Id);
            }, this);

            let label = new T.Label({
              position: nowLngLat,
              text: `<div style="transform: translateX(-100%);">${nowlocal.DeviceName}</div>`,
              offset: new T.Point(0, 28),
            });
            let itemMark = {
              data: nowlocal,
              marker: marker,
              label: label,
            };
            deviceMarkers.push(itemMark);
            map.addOverLay(marker);
            map.addOverLay(label);
          })(item);
        }
      })
      this.mapShowMarkers.device = deviceMarkers;
    },
    //清除地图上的设备
    clearMapMarkerDevice() {
      if (this.mapShowMarkers.device.length > 0) {
        this.mapShowMarkers.device.forEach(item => {
          this.tMap.removeOverLay(item.marker);
          this.tMap.removeOverLay(item.label);
        })
        this.mapShowMarkers.device = [];
      }
    },
    //================
    showMapMonitor() {
      //当地图移动时或者缩放时，缩放到16时即显示监测点位
      //在地图上显示监测点位
      //根据地图4个角计算当前可视区域内的灾害点，显示灾害点周围的监测点位
      let dangerMarkers = this.dangerMarkers;
      if (dangerMarkers.length <= 0) {
        return;
      }
      let mapBounds = this.tMap.getBounds();
      //获取在当前可视区域内显示的marker
      let mapShowMarkers = dangerMarkers.filter(item => mapBounds.contains(item.marker.getLngLat()));
      let mapNotShowMarkers = dangerMarkers.filter(item => !mapBounds.contains(item.marker.getLngLat()));

      if (mapShowMarkers.length > 0) {
        //显示当前区域的所有监测点位
        mapShowMarkers.forEach(item => {
          this.showDangerMonitor(item);
        })
      }
      // if (mapNotShowMarkers.length > 0) {
      //   mapNotShowMarkers.forEach(item => {
      //     this.clearMapMonitor(item);
      //   })
      // }
    },
    //获取灾害点下面的监测点位并显示在地图上
    showDangerMonitor(dangerMarker) {
      const that = this;
      const map = this.tMap;
      if (dangerMarker.monitor && dangerMarker.monitor.length > 0) {
        //存在数据，直接显示
        //监测点显示完以后判断是否需要显示设备
        if (that.canShowDevice) {
          that.showMonitorDevice(dangerMarker);
        }
        return;
      }
      let item = that.monitorDeviceList.find(item => item.hiddanger.Id == dangerMarker.data.Id);

      function showMarker(monitorListData) {
        let monitorMarkers = [];
        monitorListData.forEach(item => {
          var icon2 = new T.Icon({
            iconUrl: IconMapDw2,
            iconSize: new T.Point(32, 32),
            iconAnchor: new T.Point(16, 16),
          });
          if (item.Longitude && item.Latitude) {
            (function (nowlocal) {
              let nowLngLat = new T.LngLat(nowlocal.Longitude, nowlocal.Latitude);
              var marker = new T.Marker(nowLngLat,
                { icon: icon2, title: nowlocal.MonitorName, }
              );
              marker.addEventListener("click", function () {
                that.dialogMonitorId = nowlocal.Id;
                that.dialogMonitorDetailVisible = true;
              }, this);

              let label = new T.Label({
                position: nowLngLat,
                text: `<div style="transform: translateX(-70%);">${nowlocal.MonitorName}</div>`,
                offset: new T.Point(0, 28),
              });
              let itemMark = {
                data: nowlocal,
                marker: marker,
                label: label,
                devices: [],
              };
              monitorMarkers.push(itemMark);
              map.addOverLay(marker);
              map.addOverLay(label);
            })(item);
          }
        })
        dangerMarker.monitor = monitorMarkers;

        //监测点显示完以后判断是否需要显示设备
        if (that.canShowDevice) {
          that.showMonitorDevice(dangerMarker);
        }
      }

      if (item) {
        showMarker(item.monitorlist);
      } else {
        getMonitorList(1, 100, { dangerid: dangerMarker.data.Id }).then(res => {
          if (res && res.code == 0) {
            let monitorListData = res.data.data;
            showMarker(monitorListData);
          }
        })
      }


    },
    clearMapMonitor(dangerMarker) {
      //清除地图上的监测点位
      const that = this;
      if (dangerMarker && dangerMarker.monitor && dangerMarker.monitor.length > 0) {
        dangerMarker.monitor.forEach(item => {
          that.tMap.removeOverLay(item.marker);
          that.tMap.removeOverLay(item.label);

          if (item.devices && item.devices.length > 0) {
            item.devices.forEach(device => {
              that.tMap.removeOverLay(device.marker);
              that.tMap.removeOverLay(device.label);
            })
            item.devices = [];
          }
        })
        dangerMarker.monitor = undefined;
      }

      if (!dangerMarker) {
        //清除所有的点
        this.dangerMarkers.forEach(item => {
          that.clearMapMonitor(item);
        })
      }
    },


    //显示灾害点下面的所有设备在地图上
    showMonitorDevice(dangerMarker) {
      const that = this;
      const map = this.tMap;
      //地图中已显示的监测点位
      let dangerMonitorMap = dangerMarker.monitor;
      if (dangerMonitorMap && dangerMonitorMap.length > 0) {

        function showDevice(dangerMonitor) {
          let deviceList = dangerMonitor.data.deviceList;
          let deviceMarkers = [];
          deviceList.forEach(item => {
            var icon2 = new T.Icon({
              iconUrl: item.DImage,
              iconSize: new T.Point(32, 32),
              iconAnchor: new T.Point(16, 16),
            });
            if (item.Longitude && item.Latitude) {
              (function (nowlocal) {
                let nowLngLat = new T.LngLat(nowlocal.Longitude, nowlocal.Latitude);
                var marker = new T.Marker(nowLngLat,
                  { icon: icon2, title: nowlocal.DeviceName, }
                );
                marker.addEventListener("click", function () {
                  //跳转到设备详情 
                  that.$router.push('/device/detail?id=' + nowlocal.Id);
                }, this);

                let label = new T.Label({
                  position: nowLngLat,
                  text: `<div style="transform: translateX(-100%);">${nowlocal.DeviceName}</div>`,
                  offset: new T.Point(0, 28),
                });
                let itemMark = {
                  data: nowlocal,
                  marker: marker,
                  label: label,
                };
                deviceMarkers.push(itemMark);
                map.addOverLay(marker);
                map.addOverLay(label);
              })(item);
            }
          })
          dangerMonitor.devices = deviceMarkers;
        }

        dangerMonitorMap.forEach(item => {
          if (!item.devices || item.devices.length == 0) {
            showDevice(item);
          }

        })
      }


    },
    //清除设备
    clearMapDevice(dangerMarker) {
      //清除地图上的监测点位
      const that = this;
      if (dangerMarker && dangerMarker.monitor && dangerMarker.monitor.length > 0) {
        dangerMarker.monitor.forEach(item => {
          if (item.devices && item.devices.length > 0) {
            item.devices.forEach(device => {
              that.tMap.removeOverLay(device.marker);
              that.tMap.removeOverLay(device.label);
            })
            item.devices = [];
          }
        })
      }

      if (!dangerMarker) {
        //清除所有的点
        this.dangerMarkers.forEach(item => {
          that.clearMapDevice(item);
        })
      }
    },

    //地图上显示灾害点信息
    showMapHiddanger(activeIndex) {
      var nowdata = [];
      this.monitorDeviceList.forEach((element) => {
        nowdata = nowdata.concat(element.hiddanger);
      });

      if (activeIndex >= 0 && this.tMap) {
        this.tMap.centerAndZoom(
          new T.LngLat(nowdata[activeIndex].Longitude, nowdata[activeIndex].Latitude),
          16
        );
        return;
      }

      if (this.tMap) {
        var map = this.tMap;
        var that = this;
        map.clearOverLays();
        if (nowdata.length > 0) {
          let index = this.dangerActiveIndex >= 0 ? this.dangerActiveIndex : 0;
          map.centerAndZoom(
            new T.LngLat(nowdata[index].Longitude, nowdata[index].Latitude),
            16
          );
        }
        var dangerMarkers = [];
        for (let index = 0; index < nowdata.length; index++) {
          const element = nowdata[index];
          var icon1 = new T.Icon({
            iconUrl: IconMapDw1,
            iconSize: new T.Point(32, 32),
            iconAnchor: new T.Point(16, 16),
          });

          (function (nowlocal) {
            var marker = new T.Marker(
              new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
              { icon: icon1, title: nowlocal.DangerName, }
            );
            marker.addEventListener(
              "click",
              function () {
                that.dialogDangerId = nowlocal.Id;
                that.dialogDangerDetailVisible = true;
              },
              this
            );
            dangerMarkers.push({
              data: nowlocal,
              marker: marker,
            });
            map.addOverLay(marker);
            map.addOverLay(new T.Label({
              position: new T.LngLat(nowlocal.Longitude, nowlocal.Latitude),
              text: `<div style="transform: translateX(-70%);">${nowlocal.DangerName}</div>`,
              offset: new T.Point(0, 28),
            }));
          })(element);
        }
        that.dangerMarkers = dangerMarkers;
        that.showMapMonitor();
      }
    },
    changeDanger(index) {
      if (index == -1) {
        this.activeIndex = 0;
      } else {
        this.activeIndex = index;
      }
      this.dangerActiveIndex = this.activeIndex;
      this.showMapHiddanger(index);
      // if (index > -1) {
      //   var item = this.monitorDeviceList[this.activeIndex];
      //   this.dialogDangerId = item.hiddanger.Id;
      //   this.dialogDangerDetailVisible = true;
      // }
    },
  },
  created() {
    getWaringMsgAndCount().then((res) => {
      if (res.code == 0) {
        var waringDataCount = res.data.waring;
        var warningZyData = 0,
          warningJsData = 0,
          warningJjData = 0,
          warningJbData = 0;
        waringDataCount.forEach((element) => {
          if (element.type == "1") {
            warningZyData = element.count;
          } else if (element.type == "2") {
            warningJsData = element.count;
          } else if (element.type == "3") {
            warningJjData = element.count;
          } else if (element.type == "4") {
            warningJbData = element.count;
          }
        });
        this.waringDataCount = {
          warningZyData: warningZyData,
          warningJsData: warningJsData,
          warningJjData: warningJjData,
          warningJbData: warningJbData,
        };
        this.waringDataList = res.data.list;
      }
    });
    getHomeDeviceCount().then((res) => {
      if (res.code == 0) {
        this.deviceTotalCount = res.data.fcount + res.data.ocount;
        this.deviceOnlineCount = res.data.ocount;
      }
    });
    getHomeDeviceRadio().then((res) => {
      if (res.code == 0) {
        this.deviceRadioData = res.data;
      }
    });
  },
  beforeDestroy() {
    this.$tianditu.destroy();
  },
};
</script>